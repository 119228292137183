@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500&display=swap');
@import url(//db.onlinewebfonts.com/c/00370d92f1b404768d34ac7dc664289e?family=Sansation);
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans';
}

a {
  display: contents;
  text-decoration: none;
}

img {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modern-react-captcha__inputField {
  border-radius: 10px;
  position: relative;
  background-color: #F8FAFC;
  border: 1px solid #D7E2EF;
  font-size: 16px;
  width: 100%;
  padding: 15px 18px;
}

#reload_href {
  background: url(./assets/png/reload.png) 0 0 no-repeat;
  display: block;
  height: 30px;
  text-indent: -99999em;
  text-align: center;
}